<template>

  <div>

    <add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :user-data="userData"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />

    <add-scrap
      :is-add-scrap-user-sidebar-active.sync="isAddScrapUserSidebarActive"
      :user-data-scrap="userDataScrap"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!-- <list-filters
      :role-filter.sync="roleFilter"
      :role-options="roleOptions"
      :status-filter.sync="statusFilter"
      :plan-filter.sync="planFilter"
    /> -->

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <h>Perintah Baru</h><br> -->
            <b-card-code title="Icon">
              <b-card-text class="mb-0">
                <h3>Perintah Baru </h3>
              </b-card-text>

              <div class="demo-inline-spacing">
                <div
                  v-for="(lampu, index) in MLampu"
                  :key="index"
                >
                  <div style="text-align: center;"><span>{{ lampu.nama }}</span> <br>
                    <b-button-group>
                      <b-button
                        variant="gradient-primary"
                        style="width: 90px"
                        @click="AddPerintah(lampu.id, 'ON')"
                      >
                        <feather-icon
                          icon="SunIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">L{{ lampu.id }}</span>
                      </b-button>
                      <b-button
                        variant="gradient-danger"
                        style="width: 90px"
                        @click="AddPerintah(lampu.id, 'OFF')"
                      >
                        <feather-icon
                          icon="PowerIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">L{{ lampu.id }}</span>
                      </b-button>
                    </b-button-group>
                  </div>
                </div>
              </div>
              <br>
              <br>
              <b-button
                variant="success"
                @click="AddData()"
              >
                <span class="text-nowrap">Add Jadwal</span>
              </b-button>

              <template #code>
                {{ codeIcon }}
              </template>
            </b-card-code>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <br>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="6">
        <!-- siji -->
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <!-- <b-row>
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    variant="primary"
                    @click="AddData()"
                  >
                    <span class="text-nowrap">Add Pellet</span>
                  </b-button>
                </div>
              </b-col>
            </b-row> -->

          </div>

          <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="fetchData"
            responsive
            :fields="tableColumns"
            :busy.sync="isBusy"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            striped
          >

            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-button
                  variant="outline-primary"
                  disabled
                  class="mr-1"
                >
                  <b-spinner small />
                  Loading...
                </b-button>
              </div>
            </template>

            <template #cell(tanggal)="data">
              <span class="text-nowrap">
                {{ data.value }}
              </span>
            </template>

            <template #cell(nama)="data">
              <span class="text-nowrap">
                {{ data.value }}
              </span>
            </template>

            <!-- <template #cell(karyawan)="data">
              <span class="text-nowrap">
                {{ data.value }}
              </span>
            </template> -->

            <template #cell(status)="data">
              <span class="text-nowrap">
                {{ data.value }}
              </span>
            </template>
            <template #cell(perintah)="data">
              <b-badge
                pill
                :variant="`light-${resolveUserRoleVariant(data.item.perintah)}`"
                class="text-capitalize"
              >
                {{ data.item.perintah }}
              </b-badge>
            </template>

            <template #cell(status)="data">
              <div v-if="data.item.status === 'Antrian'">
                <b-button
                  size="sm"
                  :variant="`${resolveUserStatusVariant(data.item.status)}`"
                  :disabled="data.item.status !== 'Antrian'"
                  @click="DeletePerintah(data.item.id_perintah)"
                >
                  <span class="align-middle">{{ data.item.status }}</span>
                </b-button>
              </div>
              <div v-else>
                <b-badge
                  pill
                  :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                  class="text-capitalize"
                >
                  {{ data.item.status }}
                </b-badge>
              </div>

            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalData"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-col>
      <!--/ Company Table Card -->

      <!-- Developer Meetup Card -->
      <!-- loro -->
      <b-col
        lg="6"
        md="6"
      >
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage2"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions2"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery2"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    variant="primary"
                    @click="addDataScrap()"
                  >
                    <span class="text-nowrap">Add Scrap IM</span>
                  </b-button>
                </div>
              </b-col> -->
            </b-row>

          </div>

          <b-table
            ref="refUserListTable2"
            class="position-relative"
            :items="fetchData2"
            responsive
            :fields="tableColumns2"
            :busy.sync="isBusy2"
            primary-key="id"
            :sort-by.sync="sortBy2"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc2"
            striped
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-button
                  variant="outline-primary"
                  disabled
                  class="mr-1"
                >
                  <b-spinner small />
                  Loading...
                </b-button>
              </div>
            </template>

            <template #cell(perintah_lampu)="data">
              <span class="text-nowrap">
                {{ data.value }}
              </span>
            </template>

            <template #cell(karyawan)="data">
              <span class="text-nowrap">
                {{ data.value }}
              </span>
            </template>

            <template #cell(actions)="data">
              <div
                class="text-nowrap"
              >
                <div style="line-height:80%;">
                  <br>
                </div>
                <feather-icon
                  :id="`invoice-row-${data.item.id_jadwal}-send-icon-${data.item.id_lampu}`"
                  icon="EditIcon"
                  class="cursor-pointer"
                  :class="`text-primary`"
                  size="17"
                  @click="EditData(data.item)"
                />
                <b-tooltip
                  :title="`Edit Jadwal`"
                  class="cursor-pointer"
                  :target="`invoice-row-${data.item.id_jadwal}-send-icon-${data.item.id_lampu}`"
                />
&nbsp;
                <feather-icon
                  :id="`invoice-row2-${data.item.id_jadwal}-send-icon-${data.item.id_lampu}`"
                  icon="Trash2Icon"
                  class="cursor-pointer"
                  :class="`text-danger`"
                  size="17"
                  @click="DeleteJadwal(data.item.id_jadwal)"
                />
                <b-tooltip
                  :title="`Delete Jadwal`"
                  class="cursor-pointer"
                  :target="`invoice-row2-${data.item.id_jadwal}-send-icon-${data.item.id_lampu}`"
                />
              </div>
              <div style="line-height:80%;">
                <br>
              </div>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Showing {{ dataMeta2.from }} to {{ dataMeta2.to }} of {{ dataMeta2.of }} entries</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalData"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <!--/ Developer Meetup Card -->

      <!-- Browser States Card -->
      <b-col
        lg="12"
        md="12"
      >
        <!-- telu -->
      </b-col>
    </b-row>

    <br>
    <!-- Table Container Card -->

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BPagination, BButtonGroup, BTooltip, BBadge, // BDropdown, BDropdownItem,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAppConfig from '@core/app-config/useAppConfig'
// eslint-disable-next-line import/no-cycle
import useList from './useList'
import StoreModule from '../StoreModule'
import AddNew from './AddNew.vue'
import AddScrap from './AddScrap.vue'

export default {
  components: {
    AddNew,
    AddScrap,

    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BButtonGroup,
    BTooltip,
    // vSelect,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      isBusy2: false,
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, StoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const blankUserData = {
      id_lampu: '',
      // tanggal: new Date().toISOString().slice(0, 10),
      perintah: '',
      perulangan: '',
      hari: '',
      jam: '',
      user: localStorage.getItem('nik'),
      action: 'Add',
    }

    const blankUserDataScrap = {
      id_silo: '',
      tanggal: new Date().toISOString().slice(0, 10),
      nilai: '',
      id_resep: '',
      action: 'Add',
    }

    const isAddNewUserSidebarActive = ref(false)
    const isAddScrapUserSidebarActive = ref(false)

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const userDataScrap = ref(JSON.parse(JSON.stringify(blankUserDataScrap)))

    const EditData = taskData => {
      userData.value = taskData
      userData.value.user = localStorage.getItem('nik')
      isAddNewUserSidebarActive.value = true
    }

    const AddData = () => {
      userData.value = blankUserData
      isAddNewUserSidebarActive.value = true
    }

    const EditDataScrap = taskData => {
      userDataScrap.value = taskData
      isAddScrapUserSidebarActive.value = true
    }

    const addDataScrap = () => {
      userDataScrap.value = blankUserDataScrap
      isAddScrapUserSidebarActive.value = true
    }

    const roleOptions = ref([])
    // onMounted(async () => {
    //   const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/resep-ctr/view`)
    //   const dataAPI = result.data.data
    //   const combo = []
    //   // eslint-disable-next-line no-plusplus
    //   for (let i = 0, len = dataAPI.length; i < len; i++) {
    //     combo.push({ label: dataAPI[i].nama, value: dataAPI[i].id_resep })
    //   }
    //   roleOptions.value = combo
    // })

    const MLampu = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/master-lampu/view`)
      const dataAPI = result.data.data
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataAPI.length; i < len; i++) {
        combo.push({ id: dataAPI[i].id_lampu, nama: dataAPI[i].nama })
      }
      MLampu.value = combo
    })

    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      fetchData2,
      tableColumns2,
      perPage2,
      currentPage2,
      totalData2,
      dataMeta2,
      perPageOptions2,
      searchQuery2,
      sortBy2,
      isSortDirDesc2,
      refUserListTable2,

      fetchData3,
      tableColumns3,
      perPage3,
      currentPage3,
      totalData3,
      dataMeta3,
      perPageOptions3,
      searchQuery3,
      sortBy3,
      isSortDirDesc3,
      refUserListTable3,

      fetchData4,
      tableColumns4,
      perPage4,
      currentPage4,
      totalData4,
      dataMeta4,
      perPageOptions4,
      searchQuery4,
      sortBy4,
      isSortDirDesc4,
      refUserListTable4,

      fetchData5,
      tableColumns5,
      perPage5,
      currentPage5,
      totalData5,
      dataMeta5,
      perPageOptions5,
      searchQuery5,
      sortBy5,
      isSortDirDesc5,
      refUserListTable5,

      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {
      userData,
      AddData,
      EditData,

      userDataScrap,
      addDataScrap,
      EditDataScrap,
      // Sidebar
      isAddNewUserSidebarActive,
      isAddScrapUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      fetchData2,
      tableColumns2,
      perPage2,
      currentPage2,
      totalData2,
      dataMeta2,
      perPageOptions2,
      searchQuery2,
      sortBy2,
      isSortDirDesc2,
      refUserListTable2,

      fetchData3,
      tableColumns3,
      perPage3,
      currentPage3,
      totalData3,
      dataMeta3,
      perPageOptions3,
      searchQuery3,
      sortBy3,
      isSortDirDesc3,
      refUserListTable3,

      fetchData4,
      tableColumns4,
      perPage4,
      currentPage4,
      totalData4,
      dataMeta4,
      perPageOptions4,
      searchQuery4,
      sortBy4,
      isSortDirDesc4,
      refUserListTable4,

      fetchData5,
      tableColumns5,
      perPage5,
      currentPage5,
      totalData5,
      dataMeta5,
      perPageOptions5,
      searchQuery5,
      sortBy5,
      isSortDirDesc5,
      refUserListTable5,

      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      MLampu,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  methods: {
    async AddPerintah(idlampu, perintah) {
      const params = new URLSearchParams()
      params.append('id_lampu', idlampu)
      params.append('perintah', perintah)
      params.append('action', 'Add')
      params.append('user', localStorage.getItem('nik'))
      const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/perintah-lampu/crud`, params)

      if (result.data.message === 'Data Saved') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: 'Perintah Baru Berhasil Ditambahkan!',
            variant: 'success',
          },
        })

        this.refetchData()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Danger',
            icon: 'BellIcon',
            text: 'Perintah Baru Gagal Ditambahkan!',
            variant: 'danger',
          },
        })
      }
    },
    async DeletePerintah(idperintah) {
      this.$bvModal
        .msgBoxConfirm('Apakah anda yakin akan membatalkan perintah ?', {
          title: 'Batalkan Perintah',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Batalkan',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          this.boxTwo = value
          if (value === true) {
            const params = new URLSearchParams()
            params.append('action', 'Update')
            params.append('id_perintah', idperintah)
            params.append('user', localStorage.getItem('nik'))
            const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/perintah-lampu/crud`, params)
            if (result.data.message === 'Data Updated') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: 'Perintah Berhasil Dibatalkan!',
                  variant: 'success',
                },
              })
              this.refetchData()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  text: 'Perintah Gagal Dibatalkan!',
                  variant: 'danger',
                },
              })
            }
          }
        })
    },

    async DeleteJadwal(idjadwal) {
      this.boxTwo = idjadwal
      this.$bvModal
        .msgBoxConfirm('Are You Sure To Delete This Data?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          this.boxTwo = value
          if (value === true) {
            const params = new URLSearchParams()
            params.append('param', 'delete')
            params.append('id_jadwal', idjadwal)
            const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/jadwal-lampu/delete`, params)
            if (result.data.message === 'Data Deleted') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: 'Jadwal Berhasil Dihapus',
                  variant: 'success',
                },
              })
              this.refetchData()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  text: 'Gagal Menghapus Jadwal',
                  variant: 'danger',
                },
              })
            }
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
