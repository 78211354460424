<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ userData.action }} Jadwal
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Resep -->
          <validation-provider
            #default="validationContext"
            name="Lampu"
            rules="required"
          >
            <b-form-group
              label="Lampu"
              label-for="lampu"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.id_lampu"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="lampuOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="lampu"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Silo -->
          <validation-provider
            #default="validationContext"
            name="Perintah"
            rules="required"
          >
            <b-form-group
              label="Perintah"
              label-for="perintah"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.perintah"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perintahOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="perintah"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Perulangan"
            rules="required"
          >
            <b-form-group
              label="Perulangan"
              label-for="perulangan"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.perulangan"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perulanganOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="perulangan"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Hari"
            rules="required"
          >
            <b-form-group
              label="Hari"
              label-for="hari"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.hari"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="hariOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="hari"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Jam"
            rules="required"
          >
            <b-form-group
              label="Jam"
              label-for="jam"
            >
              <b-form-input
                id="jam"
                v-model="userData.jam"
                type="time"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="$emit('update:is-add-new-user-sidebar-active', false),$emit('refetch-data')"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  toRefs, ref, onMounted,
} from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import axios from '@axios'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      value: '',
      formatted: '',
      selected: '',
    }
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
  },
  setup(props, { emit }) {
    const toast = useToast()

    const lampuOptions = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/master-lampu/view`)
      const DataAPI = result.data.data
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = DataAPI.length; i < len; i++) {
        combo.push({ label: DataAPI[i].nama, value: DataAPI[i].id_lampu })
      }
      lampuOptions.value = combo
    })

    const perintahOptions = [
      { label: 'ON', value: 'ON' },
      { label: 'OFF', value: 'OFF' },
    ]

    const perulanganOptions = [
      { label: 'Berulang', value: 'Berulang' },
      { label: 'Hanya 1X', value: 'Hanya 1X' },
    ]

    const hariOptions = [
      { label: 'Senin', value: 'Senin' },
      { label: 'Selasa', value: 'Selasa' },
      { label: 'Rabu', value: 'Rabu' },
      { label: 'Kamis', value: 'Kamis' },
      { label: 'Jumat', value: 'Jumat' },
      { label: 'Sabtu', value: 'Sabtu' },
      { label: 'Minggu', value: 'Minggu' },
      { label: 'Setiap Hari', value: 'Setiap Hari' },
    ]

    const { userData } = toRefs(props)

    const onSubmit = () => {
      store.dispatch('app-user/crudData', userData.value)
        .then(response => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
          if (response.data.message === 'Data Saved' || response.data.message === 'Data Updated') {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: response.data.message,
                variant: 'success',
              },
            })
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: 'Error Saving Data / Duplicate Data',
                variant: 'danger',
              },
            })
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      userData,
      lampuOptions,
      perintahOptions,
      perulanganOptions,
      hariOptions,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
